header {
  background-color: black;
  width: 100vw;
  position: fixed;
}
nav {
  align-items: center;
  display: flex;
  font-family: sans-serif;
  min-height: 8vh;
}
.logo {
  display: none;
  font-size: 1rem;
  letter-spacing: 3px;
  text-transform: uppercase;
}
// UL Styling
.nav-list {
  align-items: center;
  background-color: #000;
  clip-path: circle(0px at 0% -10%);
  -webkit-clip-path: circle(0px at 0% -10%);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  margin-right: auto;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: all 1s ease-out;
  width: 100%;
}
.nav-list-item {
  margin: 0 auto 40px auto;
}
.active {
  color: #54ecc4;
}
.open {
  clip-path: circle(1600px at 0% -10%);
  -webkit-clip-path: circle(1600px at 0% -10%);
  pointer-events: all;
}
.nav-footer {
  display: none;
}
@media screen and (min-width: $breakpoint-tablet) {
  nav {
    justify-content: space-evenly;
  }
  .hamburger {
    display: none;
  }
  .nav-list {
    align-items: initial;
    clip-path: unset;
    flex-direction: row;
    height: initial;
    margin: 0;
    pointer-events: all;
    position: static;
    top: initial;
    width: 75%;
    min-height: 0;
    justify-content: space-between;
  }
  .nav-list-item {
    margin: 0;
  }
}
@media screen and (min-width: $breakpoint-laptop) {
  header {
    min-height: 100vh;
    width: 20vw;
  }
  nav {
    justify-content: center;
    align-items: unset;
    flex-direction: column;
    height: 100vh;
    padding: 0 20%;
  }
  .logo {
    display: block;
    font-weight: 800;
  }
  .nav-list {
    margin: 30px 0;
    flex-direction: column;
    width: initial;
  }
  .nav-list-item {
    margin: 0 0 2% 0;
  }
  .nav-footer {
    display: block;
  }
  .nav-link {
    position: relative;
    text-decoration: none;
  }
  .nav-link::before,
  .nav-link:focus::before {
    background-color: #fff;
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
    visibility: hidden;
    width: 100%;
  }
  .nav-link:hover::before,
  .nav-link:focus::before {
    transform: scaleX(1);
    visibility: visible;
  }
}

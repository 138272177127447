.affiliate-features {
	color: $positive-green;
}
.affiliate-drawbacks {
	color: $negative-red;
}
.affiliates-logo {
	width: 100%;
	height: 100px;
	object-fit: scale-down;
}
.affiliated-service-primary-info {
	height: 100%;
	display: grid;
	align-content: space-between;
}

.portfolio-project {
	padding: 5rem 0;

	h2 {
		margin-top: 0;
	}
}
.goals-improvement {
	color: $positive-green;
}

.project-links {
	display: flex;
	flex-wrap: wrap;
}
.portfolio-website-image {
	align-self: center;
}
.portfolio-project {
	align-items: center;
}
.results-p {
	margin-top: 1rem;
}

@media screen and (min-width: $breakpoint-laptop) {
	.portfolio-section {
		padding-top: 100px;
	}
	.portfolio-website-information-secondary-info {
		order: 1;
	}
}

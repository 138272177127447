// ***********
// BREAKPOINTS
// ***********
// Mobile First
$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 992px;
$breakpoint-desktop: 1200px;
$positive-green: #54ecc4;
$negative-red: #ec547c;
$cool-blue: #54c8ec;
$warm-orange: #ec7854;
$rocking-purple: #c454ec;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800&family=Mulish:ital@1&display=swap');

html {
  font-size: 100%;
} /*16px*/
.app {
  height: 100vh;
}
body {
  background: #111;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
}
section {
  padding-top: 12vh;
  min-height: 80vh;
}
p {
  color: #bbb;
  font-family: 'Mulish', sans-serif;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 1rem;
  max-width: 50ch;
}

h1,
h2,
h3,
h4,
h5 {
  color: white;
  margin: 3rem 0 1.38rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 1.3;
}
h1 {
  margin-top: 0;
  font-size: 3.052rem;
}
h2 {
  font-size: 2.441rem;
}
h3 {
  font-size: 1.953rem;
}
h4 {
  font-size: 1.563rem;
}
h5 {
  font-size: 1.25rem;
}
small,
.text_small {
  font-size: 0.8rem;
}
a {
  color: #bbb;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1em;
  text-decoration: none;
  white-space: nowrap;
  display: inline-block;
}

img {
  max-width: 100%;
}
.container {
  max-width: 75%;
  margin: 0 auto;
}
p.section-tag::before {
  content: '';
  width: 4rem;
  height: 0.25rem;
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.5rem;
}
.section-heading {
  max-width: 17ch;
}
@media screen and (min-width: $breakpoint-laptop) {
  .app {
    display: grid;
  }
  section {
    padding-top: 6.25rem;
    padding-left: 20vw;
    // display: grid;
    // align-items: center;
  }
  .grid {
    display: grid;
    grid-gap: 5rem;
  }
  .grid-twoCol {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.button {
	background-color: #fff;
	color: #000;
	padding: 0.5rem 1rem;
	margin-right: 0.5rem;
	text-align: center;
	margin-bottom: 10px;
	min-width: 90px;
}

.button:hover {
	opacity: 0.6;
}

.about-info-container {
	margin-top: 4vh;
	max-width: 55ch;
	p {
		margin: 3vh 0;
	}
}
#portrait-photo {
	margin-top: 4vh;
}
// .about-profile-image {
// 	background: url('../../Assets/BadgePhoto.png') center no-repeat;
// 	background-size: cover;
// 	min-width: 100%;
// 	min-height: 440px;
// }
